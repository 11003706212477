@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

select:focus,
textarea:focus,
input:focus {
  outline: none;
}
select,
input,
textarea {
  color-scheme: light;
  background-color: white;
}
.mirror-Animation {
  position: relative;
  overflow: hidden;
}
.mirror-Animation::before {
  content: "";
  position: absolute;
  rotate: 50deg;
  height: 500px;
  bottom: -200px;
  background: linear-gradient(90deg, #f0f5fe00 0%, #f0f5fecc 100%);
  opacity: 0.4;
  animation: mirror-animation 3.5s linear infinite;
}
@keyframes mirror-animation {
  0% {
    left: -100%;
  }
  30% {
    left: -100%;
  }
  100% {
    left: 150%;
  }
}

.vibration-animation {
  animation: vibration-animation 0.4s linear infinite;
}
@keyframes vibration-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* loader/spinner css start */
.loader {
  aspect-ratio: 1/1;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #2136d4;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loader/spinner css  end */

@layer components {
  .blue-gradient {
    @apply bg-gradient-to-br bg-[#2136d4] from-blue-500 via-blue-600 to-blue-900 text-white;
  }
  .partial-black-background {
    @apply fixed top-0 right-0 left-0 bottom-0 z-[1] bg-[#000000c8];
  }
  .fixed-items-center {
    @apply fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center;
  }
  .input2 {
    @apply border border-[#e7e7e7] rounded-lg text-[.875rem] p-1;
  }
  .img {
    @apply border border-[#e7e7e7] rounded-lg bg-[#f0f5fe] w-[4rem] h-[4rem];
  }
}
